.section-two-wrapper .puzzle-gif {
  height: 50%;
}

.section-two-wrapper .puzzle-gif:nth-child(1) {
  border-radius: var(--spacing-64);
}

.hr-small {
  width: var(--spacing-80);
  margin: auto;
  background-color: var(--color-violet-400);
  height: var(--spacing-1);
  border-radius: 50%;
}

.hr-violet {
  background-color: var(--color-violet-400);
}

.hr-white {
  background-color: white;
}
