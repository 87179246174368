.static-header .navbar {
  height: 60px;
  /* box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important; */
  box-shadow: 0 1px 3px #f4effd, 0 1px 2px rgb(0 0 0 / 12%) !important;
}

.static-header .home-page-title {
  /* font-size: 22px !important; */
  font-weight: 500;
  color: var(--color-violet-400) !important;
  /* letter-spacing: 0.015rem; */
}

.static-header .navbar .fixed-after {
  height: 30px;
}

.static-header .home-page-title.after {
  font-size: 18px !important;
}
